<template>
    <section>
        <div class="content-wrapper">
            <div class="row" style="min-height: 25rem">
                <div class="col-md-3"></div>
                <div class="col-md-9">
                    <div class="text-background">
                        <img data-aos="zoom-in" data-aos-duration="3000" id="shop-title" src="@/assets/images/import/H1_Boutique.png" alt="">
                        <img data-aos="fade-up" id="growing-leaves" src="@/assets/images/import/feuilles.png" alt="">
                        <div class="top-left">
                            <h1 data-aos="fade-left" data-aos-duration="3000">
                                Boutique 
                            </h1>
                            <h3 class="mt-2 ml-5 gold-yellow" data-aos="fade-up" data-aos-duration="3000"> Sur commande ou en magasin</h3>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Recherche et filtre + liste des produits -->
            <div class="row" data-aos="fade-up" data-aos-duration="3000">
                <div class="col-md-3" data-aos="fade-left" data-aos-duration="3000">
                    <b-form-group>
                        <b-input-group>
                            <b-input-group-text slot="prepend" class="text-white">
                            <span><i class="fa fa-search" style="color:grey"></i></span>
                            </b-input-group-text>
                            <b-form-input v-model="filtrekey" @keyup="findProduct()" placeholder="Rechercher"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <p class="gold-yellow pointer mark" v-for="(item, index) in marque" :key="index" @click="filterByMarque(item)">{{item}}</p>
                    <p class="gold-yellow pointer mark" @click="getProduit">Tous les produits</p>
                </div>
                
                <div class="col-md-9" data-aos="fade-right" data-aos-duration="2000">
                    <div class="d-flex justify-content-between">
                        <!-- <h4 class="pointer gold-yellow" @click="getProduit">Tous les produits</h4> -->
                        
                        <!-- <h4 class="pointer" v-for="(item, index) in categories" :key="index" @click="getProductByCategories(item)">{{item.category}}</h4> -->
                    </div>
                    <div class="mt-2 mb-5">
                        <h3>
                            {{mark}}
                        </h3>

                        <!-- Nohèm -->
                        <span class="light" v-if="mark=='Nohèm'">
                            <p>Éthique et engagée </p>
                            <p>
                                Nohèm achète ses ingrédients auprès des femmes du Sud, productrices des matières 1ères au cœur des formulations cosmétiques, à un prix juste, dans l’esprit d’un échange équitable, d’une relation commerciale qui permet à ces femmes de vivre décemment de leur récolte.
                                
                            </p>
                            <transition name="read-more">
                                <span v-if="readMore">
                                    <p>Soin certifié bio et responsable</p>
                                    <p>
                                        La marque utilise des ingrédients naturels (99% à 100% du total des ingrédients), biologiques (15% à 65% du total des ingrédients) et issus du commerce équitable (5 à 25% des ingrédients), certifiés par des labels bio et équitable d’Ecocert.
                                    </p>
                                </span>
                            </transition>
                            <span v-if="!readMore" class="gold-yellow pointer" @click="readMore=!readMore">Lire la suite</span>
                            <span v-else class="gold-yellow pointer" @click="readMore=!readMore">Lire moins</span>
                        </span>

                        <!-- Paris -->
                        <span class="light" v-else-if="mark=='Beauty Garden'">
                            <p>C’est en prenant soin de son jardin, à Auriac, en Corrèze, que Bernard Mas, fondateur de la marque s’est posé la question…</p>
                            <transition name="read-more">
                                <span v-if="readMore">
                                    <p>Pour y répondre, il a réuni autour de lui une équipe de passionnées de bio avec une experte en herboristerie, un maraicher, et une passionnée d’écoconception. Ensemble, ils ont créé des cosmétiques Bio.</p>
                                    <br><p>De la graine ...</p>
                                    <p>Leur jardin est certifié bio et les fleurs et légumes grandissent dans un environnement privilégié, loin de la pollution avec pour seul engrais le soleil et l’eau de pluie.</p>
                                    <br><p>A la crème ...</p>
                                    <p>L’herboriste développe chaque formule. Des soins uniques créés sur mesure pour mettre en avant les bienfaits du potager. Ses ingrédients 100% naturels, des mélanges créatifs et actifs.  Les produits sont fabriqués dans leur atelier, en Corrèze.</p>
                                </span>
                            </transition>
                            <span v-if="!readMore" class="gold-yellow pointer" @click="readMore=!readMore">Lire la suite</span>
                            <span v-else class="gold-yellow pointer" @click="readMore=!readMore">Lire moins</span>
                        </span>

                        <!-- Paris -->
                        <span class="light" v-else-if="mark=='1944 Paris'">
                            <p>La beauté écoresponsable </p>
                            <p>
                                Les ingrédients des formules sont tous rigoureusement sélectionnés pour leurs performances ciblées.
                                Sur la peau, les lèvres ou les yeux, chaque produit renferme une action protectrice et nourrissante en fonction de la zone du visage.
                            </p>
                            <transition name="read-more">
                                <span v-if="readMore">
                                    <ul>
                                        <li>Cacao : antioxydant</li>
                                        <li>Baobab : régénère et hydrate</li>
                                        <li>Noix de coco : nourrissant</li>
                                        <li>Ricin : nourrit & accélère la pousse</li>
                                        <li>sésame: antioxydant</li>
                                        <li>tournesol: nourrissant, hydratant, régénérateur</li>
                                        <li>Cameline : anti-âge, régénérant, cicatrisant</li>
                                        <li>Camomille : apaisant, tonifiant</li>
                                        <li>Beurre de Karité : nourrissant, apaisant …</li>
                                    </ul>
                                <p>Maquillage adapté aux attentes de toutes les femmes, tous les âges, tous les styles.</p>
                                </span>
                            </transition>
                            <span v-if="!readMore" class="gold-yellow pointer" @click="readMore=!readMore">Lire la suite</span>
                            <span v-else class="gold-yellow pointer" @click="readMore=!readMore">Lire moins</span>
                        </span>
                        <p v-else></p>
                    </div>
                    <div >
                        <!-- loading animation -->
                        <div v-if="!products_display" class="jumping-dots-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <!-- liste des produits -->
                        <div v-else class="product-card-meb p-2">
                            <div v-if="paginatedCards == [] || paginatedCards == null || paginatedCards == ''">
                                <img src="@/assets/images/import/no-data.png" style="width: 100%" alt="">
                                    Il n'y a pas de produit correspondant à la recherche
                            </div>
                        
                            <div v-else  class="row product-item-wrapper" >
                                <div  class="col-md-4 col-sm-12 my-3 " v-for="(item, itemID) in paginatedCards" :key="itemID">
                                    <div class="card" @click="showProduct(item.ulid)">
                                        <!-- image affichage -->
                                        <template v-if="Array.isArray(item.image) && item.image.length">
                                            <div v-for="(val,key,index) of item.image" :key="index">
                                                <span v-if="key == 0">
                                                    <picture>
                                                        <!-- <source class="card-img-top"  :srcset="val.content.webpImage" media="(min-width: 600px)"> -->
                                                        <source class="card-img-top"  :srcset="val.content.webpImage" media="image/webp">
                                                        <img class="card-img-top"  :src="val.content.originalImage" alt="Card image cap">
                                                    </picture>
                                                </span>
                                            </div>
                                        </template>
                                        <img v-else  class="card-img-top" src="@/assets/images/import/default-image.jpg"  alt="Card image cap">
                                        <!-- end card image  -->
                                        <div class="card-body p-3">
                                            <h5 class="card-title">{{item.name}}</h5>
                                            <div class="d-flex justify-content-between">
                                                <p class="card-text">{{item.description}}</p>
                                                <h2 class="text-muted product-card">{{roundNumber(item.price * 1.2)}}€</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- exemple -->
                            <!-- https://codepen.io/Hiws/pen/OJXwvMm -->
                            <b-pagination class="rounded-separated d-flex justify-content-center computer" v-if="products_display.length > 6" v-model="currentPage" :per-page="perPage" :total-rows="products_display.length"></b-pagination>
                            <div class="d-flex justify-content-center mobile">
                                <button v-if="perPage && products_display.length>0 && perPage<products_display.length" class="btn btn-gradient-primary btn-social-icon btn-rounded" @click="seeMore()">  <i class="mdi mdi-arrow-down-drop-circle-outline "></i></button>
                                <!-- <button v-if="products_display.length>0" class="btn btn-link" @click="scrollTop()">Début de la page  <i class="mdi mdi-arrow-up-drop-circle-outline "></i></button> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- /wrapper -->
        <floatGoTop class="mobile"/>
    </section>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import floatGoTop from '../components/float-go-top.vue'
import { priceMixin } from './mixins/price'
export default {
    name: 'shop',
    mixins:[priceMixin],
    components: {
        floatGoTop
    },
    data () {
        return {
            products : [],  
            products_display:[],
            categories:[],
            cat_description:"",
            filtrekey:"",
            currentPage: 1,
            perPage: 6,
            mark:"",
            readMore:false
        }
    },
    created(){
    this.getProduit()
    this.getCategories()
    },
    methods:{
        getProduit(){
            this.products_display=null
            this.currentPage = 1
            this.mark=""
            axios
            .get('products')
            .then(getProduitresponse => {
                this.products = getProduitresponse.data.result
                this.products =this.products.filter((prod, index, self) =>
                index === self.findIndex((p) => (
                    p.name === prod.name
                ))
                )
                this.products=this.products.filter(element => element.isactive)
                this.products_display =this.products
                })
            .catch((errgetProduit) => console.log({ errgetProduit }));
            this.cat_description = ""
        },
        getCategories(){
            axios
            .get('categories')
            .then(response => (this.categories = response.data.result))
            .catch((errgetCategories) => console.log({ errgetCategories }));
        },
        getProductByCategories(categories){
            this.currentPage = 1
            this.products_display=null
            let url ='categories/'+categories.ulid
            axios
            .get(url)
            .then(response => {
                this.products = response.data.products
                this.products = this.products.filter((prod, index, self) =>
                index === self.findIndex((p) => (
                    p.name === prod.name
                ))
                )
                this.products_display =this.products
                })
            .catch((errgetProductByCategories) => console.log({ errgetProductByCategories }));
            this.cat_description = categories.description
        },
        filterByMarque(marque){
            this.mark= marque
            this.currentPage = 1
            let newproduct = []
            this.products.forEach(element => {
                if(element.mark == marque){
                    newproduct.push(element)
                }
            })
            this.products_display = newproduct
        },
        findProduct()
        {
            this.products_display=null
            this.currentPage = 1
            const regex = new RegExp(this.filtrekey.toLowerCase());
            let newproduct = []
            // if (this.filtrekey == "") {
            // // newproduct = this.products
            // }
            this.products.forEach(element => {
                if(regex.test(element.name.toLowerCase())){
                    newproduct.push(element)
                }
            })
            this.products_display = newproduct
        },
        showProduct(ulidprod){
            this.$router.push(
                {
                name: 'product',
                params: {ulid: ulidprod}
                }
            )
        },
        scrollText(){
            $(document).on('scroll', function() {
            $("#shop-title").css("left", Math.max(0.1*window.scrollY, 5) + "vw");
        })
        },
        test(text){
            console.log(text)
        },
        // pagination
        seeMore() {
            console.log(this.perPage)
            this.perPage = this.perPage + 5
            // console.log(this.perPage)

            // this.products_display.length<this.perPage ? this.perPage=false : this.perPage
        },
        scrollTop () {
            window.scrollTo(0, 0);
        }
    },
    computed:{
        marque:function(){
            let arr= ['Nohèm', 'Beauty Garden', '1944 Paris']
            this.products.forEach(element => {
                if(arr.includes(element.mark)){
                    //blabla
                }else{
                arr.push(element.mark)
                }
            });
            return arr
        },
        paginatedCards() {
        const { currentPage, perPage } = this;
        const start = (currentPage - 1) * perPage;
        const end = currentPage * perPage;
        return this.products_display.slice(start, end);
        }
    },
    mounted() {
        this.scrollText()
    }
}
</script>
<style scoped>
.text-background img {
    max-width: 150%;
}

#shop-title {
    position: relative;
    /* left: 7rem */
    right: -10vw;
}
#growing-leaves {
    position: relative;
    bottom: 12rem;
    width: 4rem;
    left: 2rem;
    animation: growing ease-in-out 4s reverse;
}
@keyframes growing {
    to{
        width: 0rem;
        bottom:3rem
    }
    from{
        width: 4rem;
    }
    
}

.text-background {
    position: relative;
}
.top-left {
    position: absolute;
    top: 20%;
    left: 18%;
}
.product-card-meb {
    /* background: #F9FBF9;
    border: black 1px solid; */
}
.card-body{
    /* border: black 1px solid; */
}
.card{
    cursor: pointer;
}
/* image top */
.card-img-top{
    height: 11rem;
    object-fit: cover;
}

/* Animation sur les affichages de Lire la suite */
.read-more-enter-active, .read-more-leave-active{
  transition: opacity 1s;
}
.read-more-enter, .read-more-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

h2.product-card {
    font-size: 3rem;
}
p.card-text {
    font-size: 16px
}
/* centrer les marques sur la version mobile  */
@media screen and (max-width: 992px) {
    .mark {
        text-align: center
    }
}
</style>